import type { ManifestMap } from '@xing-com/crate-core-assets';
import type { MetadataMap } from '@xing-com/crate-xinglet';

export function extractMetadata(manifest: ManifestMap): MetadataMap {
  return Object.fromEntries(
    Object.entries(manifest).map(([name, manifest]) => {
      return [
        name,
        {
          ...manifest.metadata,
          id: manifest.id,
          name,
        },
      ];
    })
  );
}
