import type { Runtime } from './internal';

export type ErrorReporter = (
  error: Error | string,
  context?: {
    componentStack?: string;
    digest?: string;
    xinglet?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
) => void;

declare const globalThis: {
  crateRuntime: Runtime;
};

export const reportError: ErrorReporter = (error, context) => {
  globalThis.crateRuntime?.reportError(error, context);
};

/** @deprecated please use reportError instead */
export const captureException: ErrorReporter = (error, context) => {
  reportError(error, context);
};

export function combineErrorReporters(
  ...errorReporters: ErrorReporter[]
): ErrorReporter {
  return (...args) => {
    for (const errorReporter of errorReporters) {
      errorReporter(...args);
    }
  };
}
