import { redirectTo } from '@xing-com/crate-core-router';
import {
  buildRedirectUrl,
  createHost,
  XingletLoader,
} from '@xing-com/crate-runtime';
import type { BrowserHost } from '@xing-com/crate-xinglet';
import type { BrowserRuntime } from '@xing-com/crate-xinglet/internal';

import { executeCommand } from './execute-command';

function getHostname(subdomain?: string): string {
  const { hostname } = document.location;

  if (!subdomain || hostname === 'localhost') {
    return hostname;
  }

  return `${subdomain}.${hostname.replace(/^www\./, '')}`;
}

export function createBrowserHost(runtime: BrowserRuntime): BrowserHost {
  return createHost(runtime, {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    executeCommand: executeCommand.bind(null, runtime) as () => never,
    getHostname,
    redirectToLogin(flow, statusCode) {
      const { loginAppUrl } = runtime.config;

      return redirectTo(
        buildRedirectUrl(loginAppUrl, globalThis.location.href, flow),
        statusCode
      );
    },
    XingletLoader,
  });
}
