const regexp = /(?:^|;\s*)crate-enable=(.*?)(?:;|$)/;

export function hasCookieFlag(flag: string): boolean {
  const flags = document.cookie.match(regexp)?.[1]?.split(',');
  const test = flags?.includes(flag) ?? false;

  if (test) console.warn(`[CRATE] Enabled "${flag}" via cookie`);

  return test;
}
