import { Eventual } from '@xing-com/crate-xinglet';
import type { Xinglet, XingletClass } from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

export function createXinglet(
  xingletClass: XingletClass,
  name: string,
  runtime: Runtime
): Eventual<Xinglet> {
  const { host } = runtime;

  const xinglet: Xinglet = Object.assign(new xingletClass(host), {
    name,
  });

  xinglet.createContexts?.(host.provideContext(name));

  if (runtime.isServer) {
    return Eventual.resolve(xinglet);
  } else {
    return Eventual.resolve(undefined).then(async () => {
      await runtime.registerMocks?.(name);

      return xinglet;
    });
  }
}
