import type { NavigateFn } from '@reach/router';
import { useNavigate } from '@reach/router';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Component } from 'react';

class RedirectRequest {
  uri: string;
  statusCode: number;

  constructor(uri: string, statusCode: number) {
    this.uri = uri;
    this.statusCode = statusCode;
  }
}

export const isRedirect = (object: unknown): object is RedirectRequest => {
  return object instanceof RedirectRequest;
};

/**
 * Redirect to a new URL.
 *
 * This function has to be called in a component's render method because it
 * throws an exception. It can not be called in an event handler or similar. For
 * that use case, use the `navigate` function.
 *
 * @param to URL to redirect to
 * @param statusCode The status code to redirect with when the redirect happens
 *   during SSR, use 301 for permanent redirects.
 */
export const redirectTo = (to: string, statusCode = 302): never => {
  throw new RedirectRequest(to, statusCode);
};

class RedirectCatcherInner extends Component<
  PropsWithChildren<{ navigate: NavigateFn }>
> {
  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo): void {
    if (isRedirect(error)) {
      const { navigate } = this.props;
      navigate(error.uri, { replace: true });
    } else {
      throw error;
    }
  }

  render(): ReactNode {
    return this.props.children;
  }
}

/**
 * When a redirect is requested on the client, we navigate to the new location.
 * On the server, this does nothing. The exception is caught by the rendering.
 */
export const RedirectCatcher: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <RedirectCatcherInner navigate={navigate}>{children}</RedirectCatcherInner>
  );
};
