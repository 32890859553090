import { useEffect, useState } from 'react';

type LoadFunction = () => Promise<Record<string, unknown>>;
type ResolveFunction = (module: Record<string, unknown>) => React.ComponentType;

export type ImportComponentProps = {
  load: LoadFunction;
  resolve: ResolveFunction;
  [key: string]: unknown;
};

export const ImportComponent: React.FC<ImportComponentProps> = ({
  load,
  resolve,
  ...rest
}) => {
  const [{ Component, error }, setState] = useState<{
    Component?: React.ComponentType;
    error?: undefined;
  }>({});

  useEffect(() => {
    let isMounted = true;

    if (Component || error) return;

    load().then(
      (module) => {
        if (!isMounted) return;

        setState({ Component: resolve(module) });
      },
      (error) => {
        if (!isMounted) return;

        setState({ error });
      }
    );

    return () => {
      isMounted = false;
    };
  }, [Component, error, load, resolve]);

  if (!Component || error) {
    return <></>;
  }

  return <Component {...rest} />;
};

/** @deprecated Pease use a xinglet instead */
export function importComponent(
  load: LoadFunction,
  resolve: ResolveFunction = (module) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return module.default as React.ComponentType;
  }
): React.ComponentType {
  return (props: Record<string, unknown>) => {
    return <ImportComponent {...props} load={load} resolve={resolve} />;
  };
}
