import type { Xinglet } from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const executeCommand = (
  runtime: Runtime,
  command: string,
  ...args: any[]
): Promise<any | void> | any | void => {
  const commands = Object.entries(runtime.metadataMap).filter(([, metadata]) =>
    metadata.contributes?.commands?.some(({ name }) => name === command)
  );

  if (commands.length === 0) {
    throw new Error(
      `Command '${command}' not found (is the command declared in the xinglet.json?)`
    );
  }
  if (commands.length > 1) {
    throw new Error(
      `Command '${command}' is ambiguous (provided by '${commands
        .map(([name]) => name)
        .join("', ")}')`
    );
  }

  const xingletName = commands[0][0];

  const execute = (xinglet: Xinglet): any => {
    if (typeof xinglet !== 'object') {
      throw new Error(
        `Xinglet '${xingletName}' need to be an implementation of Xinglet`
      );
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (typeof (xinglet as any)[command] !== 'function') {
      throw new Error(
        `Command '${command}' of xinglet '${xingletName}' is required to be a function`
      );
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (xinglet as any)[command](...args);
  };

  const eventualXinglet = runtime.loadXinglet(xingletName);

  if (eventualXinglet.value) {
    return execute(eventualXinglet.value);
  } else {
    return Promise.resolve(eventualXinglet.then(execute));
  }
};
/* eslint-enable @typescript-eslint/no-explicit-any */
