import { joinUrl } from '@xing-com/crate-core-assets';
import { Eventual } from '@xing-com/crate-xinglet';
import type { GlobalScope, Runtime } from '@xing-com/crate-xinglet/internal';

declare const globalThis: GlobalScope;

export function tryGetModule<T>(name: string, entry?: string): T | undefined {
  const module = globalThis.crateImports?.[entry ? `${name}/${entry}` : name];

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return module as T | undefined;
}

async function loadNode(
  nodeFactory: (n: number) => HTMLElement,
  url: string,
  retry = 1
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const el = nodeFactory(retry);
    el.onload = () => {
      resolve();
    };
    el.onerror = () => {
      const error = (): void => {
        reject(
          new Error(`Failed to load "${url}"; Details may be in the dev-tools`)
        );
      };
      if (retry > 0) {
        document.head.removeChild(el);
        loadNode(nodeFactory, url, retry - 1)
          .then(resolve)
          .catch(error);
      } else {
        error();
      }
    };
    document.head.appendChild(el);
  });
}

export async function loadScript(url: string): Promise<void> {
  return loadNode(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = url;
    return script;
  }, url);
}

export async function loadStyle(url: string): Promise<void> {
  return loadNode(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    return link;
  }, url);
}

export function importModule<T>(
  runtime: Runtime,
  name: string,
  entry = 'main'
): Eventual<T> {
  return Eventual.resolve(tryGetModule<T>(name, entry)).then((module) => {
    if (module) return module;

    const manifest = runtime.manifestMap[name];
    const chunk = entry === 'main' ? manifest.entry : manifest.chunks[entry];

    if (!chunk) {
      throw new Error(`Unknown module chunk name '${entry}'`);
    }

    const url = joinUrl(runtime.config.assetsBaseUrl, chunk);

    return Eventual.resolve(loadScript(url)).then(() => {
      const module = tryGetModule<T>(name, entry);

      if (!module) {
        throw new Error(`Failed to import module '${name}'`);
      }

      return module;
    });
  });
}
