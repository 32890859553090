import type {
  BrowserBootstrapConfig,
  GlobalScope,
} from '@xing-com/crate-xinglet/internal';

import { configureFetchWithAcceptLanguageHeader } from '../shared/configure-fetch-with-accept-language-header';
import type { FetchFunction } from '../types';

import { configureFetchWithAuthCheck } from './configure-fetch-with-auth-check';
import { configureFetchWithCsrfHeader } from './configure-fetch-with-csrf-header';
import { createRequest } from './create-request';

declare const globalThis: GlobalScope;

export function configureFetch(
  runtimeConfig: BrowserBootstrapConfig
): FetchFunction {
  let configuredFetch = window.fetch;

  configuredFetch = configureFetchWithAcceptLanguageHeader(
    { fetch: configuredFetch, Request, Response },
    runtimeConfig
  );
  configuredFetch = configureFetchWithAuthCheck(configuredFetch, runtimeConfig);
  configuredFetch = configureFetchWithCsrfHeader(configuredFetch);

  return (...args) => configuredFetch(createRequest(...args));
}

export const fetch = configureFetch(globalThis.crate);
