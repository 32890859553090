import { useRuntime } from '../internal';

export const useServerData = <T>(key: string, initialValue: T): T => {
  const runtime = useRuntime();
  if (runtime.isServer) {
    return (runtime.serverData[key] = initialValue);
  } else {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (runtime.serverData[key] as T | undefined) ?? initialValue;
  }
};
