import { useContext } from 'react';

import type { Runtime } from './runtime';
import { context } from './runtime-context';

export function useRuntime(): Runtime {
  const runtime = useContext(context)?.runtime;

  if (!runtime) {
    throw new Error('No runtime context found. Please setup the HostProvider');
  }

  return runtime;
}
