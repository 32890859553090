import { configureFetch } from '@xing-com/crate-core-fetch/src/browser';
import type {
  CommonRuntimeOptions,
  XingletClass,
} from '@xing-com/crate-runtime';
import { createRuntime } from '@xing-com/crate-runtime';
import type { Eventual } from '@xing-com/crate-xinglet';
import type { BrowserRuntime } from '@xing-com/crate-xinglet/internal';

import { createBrowserHost } from './create-browser-host';
import { importModule } from './import-module';
import { importXinglet } from './import-xinglet';

export type BrowserRuntimeOptions = CommonRuntimeOptions;

export function createBrowserRuntime({
  config,
  fetch,
  ...options
}: BrowserRuntimeOptions): BrowserRuntime {
  const runtime = createRuntime({
    isServer: false,
    ...options,
    config,
    fetch: fetch ?? configureFetch(config),
    importModule<T>(name: string, entry?: string): Eventual<T> {
      return importModule<T>(runtime, name, entry);
    },
    importXinglet(name: string): Eventual<XingletClass> {
      return importXinglet(runtime, name);
    },
    registerMocks: config.enableMocks
      ? async (xingletName) => {
          const { registerMocks } = await import('./mocks');
          await registerMocks(runtime, xingletName);
        }
      : undefined,
  });

  const host = createBrowserHost(runtime);

  Object.assign(runtime, { host });

  return runtime;
}
