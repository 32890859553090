import type { Host } from '../host';
import { useRuntime } from '../internal';

export function useHost<T = unknown>(): Host & T {
  return useRuntime().host;
}

export function useContext<T>(contextId: string): T {
  return useHost().consumeContext<T>(contextId);
}
