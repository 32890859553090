import { useCallback } from 'react';

import { ErrorBoundary } from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

export interface XingletErrorBoundaryProps {
  fallback?: React.ReactElement | null;
  runtime: Runtime;
  xinglet: string;
}

const Fallback: React.FC<{ xinglet: string; error: Error }> = ({
  error,
  xinglet,
}) => {
  return (
    <div style={{ color: 'red', fontFamily: 'monospace', padding: '40px' }}>
      <strong>Failed to load xinglet &quot;{xinglet}&quot;:</strong>
      <br />
      {error.message}
    </div>
  );
};

export const XingletErrorBoundary: React.FC<
  React.PropsWithChildren<XingletErrorBoundaryProps>
> = ({ children, fallback = null, runtime, xinglet }) => {
  const onError = useCallback(
    (error: Error): React.ReactElement => {
      if (fallback) {
        return fallback;
      } else if (LOCAL_DEVELOPMENT) {
        return <Fallback error={error} xinglet={xinglet} />;
      }

      return <></>;
    },
    [fallback, xinglet]
  );

  if (runtime.isServer) return <>{children}</>;

  return (
    <ErrorBoundary context={{ xinglet }} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};
