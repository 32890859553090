import type { FC } from 'react';
import { useEffect, useState } from 'react';

import type {
  XingletComponent,
  XingletLoaderProps,
} from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

import { XingletErrorBoundary } from './xinglet-error-boundary';

export type InternalXingletLoaderProps = XingletLoaderProps & {
  runtime: Runtime;
};

// todo: rewrite to use React.Suspense/React.lazy for better UX
// this will need a bit of extra-work since React.lazy is currently
// not very dynamic and could lead to memory-leaks if recreated during
// re-render
const InternalXingletLoader: FC<InternalXingletLoaderProps> = ({
  fallback = null,
  name,
  runtime,
  ...rest
}) => {
  const [state, setState] = useState<{
    name: string;
    error?: unknown;
    Component?: XingletComponent;
  }>({ name });

  const ssr = runtime.metadataMap[name]?.ssr ?? false;
  const skipRendering = runtime.isServer && !ssr;
  const eventualComponent = skipRendering
    ? undefined
    : runtime.loadXingletComponent(name);

  const currentName = state.name;
  const error = eventualComponent?.error ?? state.error;
  const Component = eventualComponent?.value ?? state.Component;

  useEffect(() => {
    if (currentName === name && (Boolean(Component) || Boolean(error))) {
      return;
    }

    let mounted = true;

    eventualComponent?.then(
      (Component) => {
        if (!mounted) return;

        setState({ name, Component });
      },
      (error) => {
        if (!mounted) return;

        setState({ name, error });
      }
    );

    return () => {
      mounted = false;
    };
  }, [currentName, name, eventualComponent, runtime, error, Component]);

  useEffect(() => {
    return runtime.events.on('xinglet-reloaded', (otherName) => {
      if (otherName !== name || !Component) return;
      setState({ name });
    });
  }, [Component, runtime, name]);

  if (error) {
    if (error instanceof Error && !error.message.startsWith(`${name}:`)) {
      error.message = `${name}: ${error.message}`;
    }

    throw error;
  }

  if (!Component && !skipRendering) {
    runtime.events.emit('xinglet-awaited', name);
  }

  return Component ? <Component {...rest} /> : fallback;
};

export const XingletLoader: FC<InternalXingletLoaderProps> = ({
  error: errorFallback,
  fallback,
  name,
  runtime,
  ...rest
}) => {
  return (
    <XingletErrorBoundary
      fallback={errorFallback}
      runtime={runtime}
      xinglet={name}
    >
      <InternalXingletLoader
        {...rest}
        fallback={fallback}
        name={name}
        runtime={runtime}
      />
    </XingletErrorBoundary>
  );
};
