import { createContext } from 'react';

import type { Runtime } from './runtime';

export type RuntimeContext = {
  runtime: Runtime;
};

export const context = createContext<RuntimeContext | undefined>(undefined);
context.displayName = 'RuntimeContext';

export const RuntimeProvider: React.FC<
  React.PropsWithChildren<RuntimeContext>
> = ({ children, runtime }) => {
  return <context.Provider value={{ runtime }}>{children}</context.Provider>;
};
