export class EventEmitter<T> {
  private handlers: {
    [eventName in keyof T]?: ((value: T[eventName]) => void)[];
  };

  constructor() {
    this.handlers = {};
  }

  emit<K extends keyof T>(event: K, value: T[K]): void {
    this.handlers[event]?.forEach((handler) => handler(value));
  }

  on<K extends keyof T>(event: K, handler: (value: T[K]) => void): void {
    if (!this.handlers[event]) {
      this.handlers[event] = [handler];
    } else {
      this.handlers[event]?.push(handler);
    }
  }

  once<K extends keyof T>(
    event: K,
    handler: (value: T[K]) => void
  ): () => void {
    const innerHandler = (value: T[K]): void => {
      handler(value);

      this.off(event, innerHandler);
    };

    this.on(event, innerHandler);

    return () => {
      this.off(event, innerHandler);
    };
  }

  off<K extends keyof T>(event: K, handler: (value: T[K]) => void): void {
    const index = this.handlers[event]?.indexOf(handler) ?? -1;

    if (index >= 0) {
      this.handlers[event]?.splice(index, 1);
    }
  }

  clear(): void {
    this.handlers = {};
  }
}
