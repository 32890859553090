import { joinUrl } from './join-url';

export function getManifestUrl({
  assetsBaseUrl,
  manifestId,
  manifestScope,
}: {
  assetsBaseUrl: string;
  manifestId: string;
  manifestScope: string;
}): string {
  return joinUrl(
    assetsBaseUrl,
    'runtime',
    `manifest-${manifestScope}-${manifestId}.json`
  );
}

export function getJsonpName(name: string): string {
  name = name
    .replace(/[_-][a-z]/gi, (c) => c[1].toUpperCase())
    .replace(/-/g, '_');

  return `${name}Jsonp`;
}
