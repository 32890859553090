import type {
  BrowserHost,
  Host,
  ServerHost,
  XingletLoaderProps,
} from '@xing-com/crate-xinglet';
import type {
  BrowserRuntime,
  Runtime,
  ServerRuntime,
} from '@xing-com/crate-xinglet/internal';
import { RuntimeProvider } from '@xing-com/crate-xinglet/internal';

import { createContextHelper } from './context-helper';
import type { InternalXingletLoaderProps } from './xinglet-loader';

interface CommonHostOptions {
  executeCommand(): never;
  getHostname(subdomain?: string): string;
  redirectToLogin(path?: string, statusCode?: 301 | 302): never;
  XingletLoader: React.ComponentType<InternalXingletLoaderProps>;
}

export interface BrowserHostOptions extends CommonHostOptions {
  setHeader?: never;
  setResponseStatusCode?: never;
}

export interface ServerHostOptions extends CommonHostOptions {
  setHeader: ServerHost['setHeader'];
  setResponseStatusCode: ServerHost['setResponseStatusCode'];
}

type HostOptions = BrowserHostOptions | ServerHostOptions;

export function createHost(
  runtime: BrowserRuntime,
  options: BrowserHostOptions
): BrowserHost;
export function createHost(
  runtime: ServerRuntime,
  options: ServerHostOptions
): ServerHost;
export function createHost(
  runtime: Runtime,
  { XingletLoader, setHeader, setResponseStatusCode, ...rest }: HostOptions
): Host {
  const { config, metadataMap } = runtime;
  const { isPreview = false } = config;
  const metadata = Object.values(metadataMap);
  const {
    //
    ContextProvider,
    consumeContext,
    provideContext,
  } = createContextHelper();

  const common = {
    consumeContext,
    getXingletMetadata: () => metadata,
    isPreview,
    provideContext: (name: string) => provideContext(runtime.host, name),
    XingletLoader: (props: XingletLoaderProps) => {
      return (
        <RuntimeProvider runtime={runtime}>
          <ContextProvider name={props.name} host={runtime.host}>
            <XingletLoader {...props} runtime={runtime} />
          </ContextProvider>
        </RuntimeProvider>
      );
    },
  };

  if (runtime.isServer) {
    const { request } = runtime;

    return {
      isServer: true,
      ...rest,
      request,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setHeader: setHeader!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setResponseStatusCode: setResponseStatusCode!,
      ...common,
    };
  } else {
    return {
      isServer: false,
      ...rest,
      ...common,
    };
  }
}
