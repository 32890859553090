import { useEffect } from 'react';

import type { XingletComponent } from '@xing-com/crate-xinglet';
import type { Runtime } from '@xing-com/crate-xinglet/internal';

export function createComponent(
  name: string,
  runtime: Runtime,
  Component: XingletComponent
): XingletComponent {
  return (props) => {
    runtime.events.emit('xinglet-rendered', name);

    useEffect(() => {
      runtime.events.emit('xinglet-mounted', name);
    });

    return <Component {...props} />;
  };
}
