import { useEffect, useState } from 'react';

import type { Eventual } from '../eventual';
import { useRuntime } from '../internal';
import type { Xinglet, XingletComponent } from '../xinglet';

export type AsyncXinglet =
  | { loading: true; xinglet: undefined; error: undefined }
  | { loading: false; xinglet: Xinglet; error: undefined }
  | { loading: false; xinglet: undefined; error: Error }
  | { loading: false; xinglet: undefined; error: undefined };

function wrapError(error: unknown): Error {
  return error instanceof Error ? error : new Error(String(error));
}

function asyncXinglet(
  xinglet: Eventual<Xinglet>,
  component: Eventual<XingletComponent>,
  ssr: boolean
): AsyncXinglet {
  if (!ssr) {
    return { loading: false, xinglet: undefined, error: undefined };
  }

  const error = xinglet.error || component.error;

  return error
    ? { loading: false, xinglet: undefined, error: wrapError(error) }
    : xinglet.value && component.value
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { loading: false, xinglet: xinglet.value!, error: undefined }
      : { loading: true, xinglet: undefined, error: undefined };
}

export function useXinglet(name: string): AsyncXinglet {
  const runtime = useRuntime();
  const { ssr } = runtime.metadataMap[name];
  const eventualXinglet = runtime.loadXinglet(name);
  const eventualComponent = runtime.loadXingletComponent(name);
  const [result, setResult] = useState(
    asyncXinglet(eventualXinglet, eventualComponent, ssr)
  );

  useEffect(() => {
    if (!result.loading) return;

    eventualComponent.then(
      () => {
        setResult({
          loading: false,
          xinglet: eventualXinglet.value,
          error: undefined,
        });
      },
      (error) => {
        setResult({
          loading: false,
          xinglet: undefined,
          error: wrapError(error),
        });
      }
    );
  });

  return result;
}

export function useXinglets(names: string[]): { loading: boolean } {
  const runtime = useRuntime();

  const eventualXinglets = names.map((name) => {
    return runtime.loadXinglet(name);
  });

  const [ready, setReady] = useState(
    eventualXinglets.every((eventual) => eventual.settled)
  );

  if (!ready) {
    Promise.all(eventualXinglets).then(() => {
      setReady(true);
    });
  }

  return { loading: !ready };
}
