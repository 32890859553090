import { getManifestUrl } from '@xing-com/crate-core-assets';
import type { ManifestMap } from '@xing-com/crate-core-assets';
import type { RuntimeConfig } from '@xing-com/crate-xinglet/internal';

export async function loadManifest(
  runtimeConfig: RuntimeConfig
): Promise<ManifestMap> {
  const manifestUrl = getManifestUrl(runtimeConfig);

  return await fetch(manifestUrl).then((result) => {
    return result.json();
  });
}
