import './bootstrap';

import { createRouteMatcher } from '@xing-com/crate-core-router';
import type { ErrorReporter } from '@xing-com/crate-xinglet';
import { combineErrorReporters } from '@xing-com/crate-xinglet';
import type { GlobalScope } from '@xing-com/crate-xinglet/internal';

import { setupDataDog } from './datadog';
import { hasCookieFlag } from './has-cookie-flag';
import { loadManifest } from './load-manifest';
import { render } from './render';

declare const globalThis: GlobalScope;

(async () => {
  let reportError: ErrorReporter = (
    error,
    { componentStack, ...context } = {}
  ) => {
    if (error instanceof Error) {
      if (componentStack) {
        console.error(error.message, componentStack);
      } else {
        console.error(error.message);
      }
    } else {
      console.error(error);
    }

    if (Object.values(context).filter(Boolean).length) {
      console.error('The above error provided this context', context);
    }
  };

  try {
    const {
      startXinglets = [],
      dataDog,
      serverData = {},
      ...config
    } = globalThis.crate;

    const manifestMap = await loadManifest(config);
    const routeMatcher = createRouteMatcher(manifestMap);
    reportError = combineErrorReporters(
      reportError,
      setupDataDog(routeMatcher, dataDog)
    );

    if (LOCAL_DEVELOPMENT || hasCookieFlag('report-error')) {
      const { reportErrorToScreen } = await import('./dev-mode');

      reportError = combineErrorReporters(reportError, reportErrorToScreen);
    }

    await render({
      config,
      manifestMap,
      reportError,
      startXinglets,
      serverData,
    });
  } catch (error) {
    reportError(error);
    throw error;
  }
})().catch((error) => {
  console.error(error);
  const pre = document.createElement('pre');
  const text = document.createTextNode(error.stack ?? error);
  pre.appendChild(text);
  document.getElementById('app')?.replaceWith(pre);
});
