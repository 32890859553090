import { useRuntime } from '../internal';
import type { XingletComponent } from '../xinglet';

export function useSuspenseXinglet(name: string): XingletComponent {
  const runtime = useRuntime();
  const eventual = runtime.loadXingletComponent(name);

  if (!eventual.settled) {
    throw Promise.resolve(eventual);
  } else if (eventual.error) {
    throw eventual.error;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return eventual.value!;
  }
}
