import type { JSONSchemaForXingletConfigFiles as XingletConfig } from './xinglet-schema';

type Permutations<T, U = T> = [T] extends [never]
  ? []
  : T extends T
    ? [T, ...Permutations<Exclude<U, T>>]
    : never;

export type ChunkMap = Record<string, string>;

export type ManifestScopes = Exclude<
  XingletConfig['manifestScopes'],
  undefined | '*'
>;

export type ManifestScope = ManifestScopes[number];

export const MANIFEST_SCOPES = Object.freeze<Permutations<ManifestScope>>([
  'crate',
  'brewery',
  'demo',
  'xing',
]);

export type Manifest = {
  id: string;
  entry: string;
  assets: string[];
  chunks: ChunkMap;
  metadata: {
    dependencies: string[];
    frontmatters?: unknown[];
    contributes: Exclude<XingletConfig['contributes'], undefined>;
    owner?: string;
    ssr: boolean;
  };
};

export type ManifestMap = Record<string, Manifest>;
