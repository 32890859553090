import { useEffect, type FC, type ReactElement } from 'react';

import type { Runtime } from '@xing-com/crate-xinglet/internal';
import { RuntimeProvider } from '@xing-com/crate-xinglet/internal';

const LoadingStateHandler: FC<{ runtime: Runtime }> = ({ runtime }) => {
  useEffect(() => {
    (function waitForXinglets() {
      const loadingXinglets = [...runtime.cachedXinglets.values()].filter(
        (eventual) => !eventual.settled
      );

      if (loadingXinglets.length) {
        Promise.all(loadingXinglets).then(waitForXinglets, waitForXinglets);
      } else {
        document
          .querySelector('#app[data-mountpoint]')
          ?.setAttribute('data-xinglets-settled', '');
      }
    })();
  });

  return <></>;
};

export function createEntryPoint(
  runtime: Runtime,
  name: string,
  props: Record<string, unknown> = {}
): ReactElement {
  return (
    <RuntimeProvider runtime={runtime}>
      <LoadingStateHandler runtime={runtime} />
      <runtime.host.XingletLoader {...props} runtime={runtime} name={name} />
    </RuntimeProvider>
  );
}
